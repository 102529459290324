import React from "react"
import "./App.css"

import { GoogleOAuthProvider } from "@react-oauth/google"

// Routing
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Pages from "./Pages"

function App() {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
    >
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Pages.Home />} />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  )
}

export default App
