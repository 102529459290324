import React from "react"

import { GoogleLogin } from "@react-oauth/google"

import axios from "axios"

const LoginButton = () => {
  return (
    <GoogleLogin
      onSuccess={(credentialResponse) => {
        const body = {
          credential: credentialResponse.credential,
        }
        axios
          .post("/google-auth", body)
          .then((res) => {
            console.log(res.data)
          })
          .catch((err) => console.log(err.response))
      }}
      onError={() => {
        console.log("Login Failed")
      }}
    />
  )
}

export default LoginButton
