import React from "react"

import axios from "axios"

import LoginButton from "../components/LoginButton"

const Home = () => {
  const test = () => {
    axios
      .get("/test")
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }
  return (
    <div>
      <h1>Home</h1>
      <LoginButton />
      <button onClick={test}>Test</button>
    </div>
  )
}

export default Home
